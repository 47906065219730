declare interface QueueItem
{
	readonly from: string;
	readonly to: string;
	readonly start: number;
	readonly end: number;
	char?: string;
}

const CHARS = '!<>-_\\/[]{}—=+*^?#________    ';

class TextScramble
{
	private readonly _originalText: string;
	
	private _showingOriginalText: boolean = true;
	
	private _queue: QueueItem[] = [];

	private _frameRequest: number = 0;

	private _frame: number = 0;

	private _resolve!: () => void;

	constructor(
		private readonly _el: HTMLElement,
		private readonly _replacementText: string,
	)
	{
		this._originalText = _el.innerText;
		this.update = this.update.bind(this);
	}

	public toggle(): Promise<void>
	{
		const oldText = this._showingOriginalText ? this._replacementText : this._originalText;
		const newText = this._showingOriginalText ? this._originalText : this._replacementText;
		this._showingOriginalText = !this._showingOriginalText;

		const length = Math.max(oldText.length, newText.length);
		const promise = new Promise<void>((resolve) => (this._resolve = resolve));
		
		this._queue = [];
		for (let i = 0; i < length; i++) {
			const from = oldText[i] || '';
			const to = newText[i] || '';
			const start = Math.floor(Math.random() * 15);
			const end = start + Math.floor(Math.random() * 15);
			this._queue.push({ from, to, start, end });
		}
		
		cancelAnimationFrame(this._frameRequest);
		
		this._frame = 0;
		this.update();
		return promise;
	}

	private update(): void
	{
		let output = '';
		let complete = 0;
		
		for (let i = 0, n = this._queue.length; i < n; i++) {
			let { from, to, start, end, char } = this._queue[i];
			if (this._frame >= end) {
				complete++;
				output += to;
			} else if (this._frame >= start) {
				if (!char || Math.random() < 0.28) {
					char = this.randomChar();
					this._queue[i].char = char;
				}
				output += `<span class="dud">${char}</span>`;
			} else {
				output += from;
			}
		}
		
		this._el.innerHTML = output;
		
		if (complete === this._queue.length) {
			this._resolve();
		} else {
			this._frameRequest = requestAnimationFrame(this.update);
			this._frame++;
		}
	}

	private randomChar(): string
	{
		return CHARS[Math.floor(Math.random() * CHARS.length)];
	}
}

(() => {
	const groupButtons = document.querySelectorAll<HTMLElement>('[data-scramble-target]');

	groupButtons.forEach(button => {
		const groupName = button.getAttribute('data-scramble-target');
		if (groupName === null) {
			return;
		}

		const group = document.querySelector(`[data-scramble-group="${groupName}"]`);
		if (group === null) {
			return;
		}

		const textElements = group.querySelectorAll<HTMLElement>('[data-scramble-text]');
		const scramblers: TextScramble[] = [];

		const buttonScrambleText = button.getAttribute('data-scramble-text');
		if (buttonScrambleText !== null) {
			scramblers.push(new TextScramble(button, buttonScrambleText));
		}

		textElements.forEach(el => {
			const scrambleText = el.getAttribute('data-scramble-text');
			if (scrambleText === null) {
				return;
			}
			
			scramblers.push(new TextScramble(el, scrambleText));
		});

		button.addEventListener('click', () => {
			scramblers.forEach(x => x.toggle());
		});
	});
})();
