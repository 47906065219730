import { load as loadFont } from 'webfontloader/webfontloader.js';


export function initJetbrainsMonoFonts(): void
{
	loadFont({
		custom: {
			families: ['JetBrains Mono'],
		},
	});
}
